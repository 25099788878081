<template>
  <el-dialog v-bind="$attrs" title="Sign Document" :width="getWidth()" :before-close="handleClose">
    <el-form :model="form" :rules="rules" ref="form">
      <el-form-item label="Name:" prop="name">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="E-Signature (please draw in the box to sign):" prop="sign">
        <vue-sign ref="vueSign" :width="500" :height="300"></vue-sign>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="mini" @click="submit">Submit</el-button>
        <el-button @click="close" size="mini">Cancel</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>
<script>
import VueSign from "@/components/vue-sign.vue";
import {uuid} from "@/utils/uuid";
import {singleleUploadFile, uploadFile} from "@/services";

export default {
  name: "signDocument",
  components: {VueSign},
  data() {
    return {
      form: {
        sign: '',
        name: ''
      },
      rules: {
        name: [
          {
            required: true, message: 'please input', trigger: ['blur', 'change']
          }
        ],
        sign: [
          {
            required: true, message: 'please enter', trigger: ['blur', 'change']
          }
        ]
      },
      isMobile: false
    }
  },
  created() {
    this.isMobile = ( this.screenType?.isMobileScreen ?? false ) || screen.width < 650
  },
  methods: {
    clear(){
      this.form = {}
      this.$refs.vueSign.reset()
      this.$refs.form.clearValidate()
    },
    close() {
      this.$emit('close')
    },
    async submit() {
      try {
        const res = await this.$refs.vueSign.generate()
        if (res) {
          this.form.sign = res
        }
        this.$refs.form.validate(val => {
          if (val) {
            var arr = res.split(","),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);
            while (n--) {
              u8arr[n] = bstr.charCodeAt(n);
            }
            let file = new File([u8arr], `${uuid()}.png`, {type: mime});
            singleleUploadFile(file).then(res=>{
              this.form.eSignature=res.url
              this.$emit('signSubmit', this.form)
            })
          } else {
            return false;
          }
        })
      } catch (err) {
        this.$refs.form.validate()
      }
    },
    getWidth() {
      return this.isMobile ? "100%" : "525px";
    },
    handleClose(done) {
      done();
    },
  }
}
</script>


<style scoped lang="scss">

</style>
