import { render, staticRenderFns } from "./PreTransfer.vue?vue&type=template&id=0a2b0a10&scoped=true&"
import script from "./PreTransfer.vue?vue&type=script&lang=js&"
export * from "./PreTransfer.vue?vue&type=script&lang=js&"
import style0 from "./PreTransfer.vue?vue&type=style&index=0&id=0a2b0a10&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a2b0a10",
  null
  
)

export default component.exports