<template>
  <div class="jetty-schedule ml-2">
    <div class="head">
      {{ $t("common.jettySchedule") }}
    </div>
    <div>
      <el-table
        :cell-style="getCellStyle"
        :data="tableData"
        style="width: 100%"
        :height="360"
        v-loading="tableLoading"
      >
        <el-table-column
          prop="berthName"
          :label="$t('table.Name')"
          align="left"
        />
        <el-table-column
          prop="bargeName"
          :label="$t('table.barge')"
          align="left"
        />
        <el-table-column prop="name" :label="$t('table.LOA')" align="left" />
        <el-table-column
          prop="berthAssignTiming"
          :label="$t('table.nextETA')"
          align="left"
        />
      </el-table>
    </div>
  </div>
</template>

<script>
import { getBerth } from "@/services";
export default {
  data() {
    return {
      tableData: [],
      tableLoading: false,
    };
  },
  methods: {
    getCellStyle({ rowIndex }) {
      if (rowIndex % 2 === 1) {
        return {
          backgroundColor: "rgba(255,255,255,0.2)",
        };
      } else {
        return {
          backgroundColor: "rgba(228,228,228,0.2)",
        };
      }
    },
    getList() {
      this.tableLoading = true;
      getBerth()
        .then((res) => {
          this.tableData = res.data;
        })
        .finally(() => (this.tableLoading = false));
    },
  },
  created() {
    this.getList();
  },
};
</script>

<style lang="scss" scoped>
.jetty-schedule {
  background: #ffffff;
  // max-height: 380px;
  height: 452px;
  // overflow-y: scroll;
  box-shadow: 0px 4px 4px 1px rgba(196, 196, 196, 0.1);
  border-radius: 2px;
  .head {
    font-weight: 700;
    padding: 15px 22px;
    border-bottom: 4px solid #fafafa;
    color: #4c565c;
    font-size: 15px;
  }
}
::v-deep .el-table::before {
  height: 0;
}
::v-deep .el-table th.el-table__cell > .cell {
  font-size: 12px;
  color: #82889c;
}
::v-deep .el-table__row > .el-table__cell > .cell {
  font-weight: 500;
  font-size: 12px;
  color: #2e3139;
}
</style>
