import request from "@/services/request";
export function selectFromById(params){
    return request({
        url:'/jput/form/selectFromById',
        method:'get',
        params
    })
}
export function saveOrUpdateForms(data){
    return request({
        url:'/jput/form/saveOrUpdateForms',
        method:'post',
        data:JSON.stringify(data),
    })
}
export function pilotSaveOrUpdateTimesheet(data){
    return request({
        url:'/jput/form/pilot/saveOrUpdateTimesheet',
        method:'post',
        data:JSON.stringify(data),
    })
}
export function pilotReportEquipment(params){
    return request({
        url:'/jput/form/pilot/reportEquipment',
        method:'get',
        params
    })
}
export function pilotSaveReportEquipment(data){
    return request({
        url:'/jput/form/pilot/saveReportEquipment',
        method:'post',
        data:JSON.stringify(data),
    })
}
export function getEquipmentReports(params) {
    return request({
        url:'/jput/form/pilot/getReportEquipmentList',
        method:'get',
        params
    })
}
export function pilotResolveReportEquipment(data) {
    return request({
        url:'/jput/form/pilot/resolveReportEquipment',
        method:'post',
        data:JSON.stringify(data),
    })
}
export function getTugBoat(params) {
    return request({
        url:'/jput/form/pilot/tugBoat',
        method:'get',
        params
    })
}
export function saveTugBoat(data) {
    return request({
        url:'/jput/form/pilot/saveTugBoat',
        method:'post',
        data:JSON.stringify(data),
    })
}
export function signForm(data){
    return request({
        url:'/jput/form/signForm',
        method:'post',
        data
    })

}
