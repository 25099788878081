<template>
<div class="form p-12">
  <div class="f-500 black">
    Totaliser Readings (Before Loading)
  </div>
  <div class="w-full xl:w-3/5">
    <div class="item mt-4">
      <div class="label fs-12">
        <span style="color:red">*</span> Reading Before Loading:
      </div>
      <div class="value w-full mt-2  xl:max-w-xs xl:mt-0">
        <el-input v-model="declarationRecordReport.readBeforeLoad" :disabled="disabled||$store.state.userInfo.role!='11'" :step="0.1" size="mini" type="number" >
          <template slot="append">
            m³
          </template>
        </el-input>
      </div>
    </div>
    <div class="item mt-4">
      <div class="label fs-12">Hose:</div>
      <div class="value w-full mt-2  xl:max-w-xs xl:mt-0">
        <el-select v-model="declarationRecordReport.hose" style="width: 100%;" :disabled="disabled||$store.state.userInfo.role!='11'" size="mini">
          <el-option v-for="(hose) in hoses" :key="index" :label="hose" :value="hose"></el-option>
        </el-select>
      </div>
    </div>
  </div>
  <ul class="mt-4">
    <li>I have examined and taken the readings of the meter before and after the loading operation.</li>
    <li>I hereby confirm that the quantity of product loaded on board my vessel as stated in this Meter Reading Report is correct.</li>
  </ul>
  <div class="mt-4 f-500" style="color:#4C565C ">
    Signature
  </div>
  <div class="flex flex-wrap w-full xl:w-1/2 mt-4 justify-between">
    <div class="w-full sm:w-1/2 ">
      <div class="f-600">Terminal Representative</div>
      <div class="mt-4 f-600" style="font-size: 12px">
        <span>Name:</span>
        <span class="ml-2">{{sign.terminal.name}}</span>
      </div>
      <div class="mt-4 f-600" style="font-size: 12px">
        <span>Date and Time:</span>
        <span class="ml-2">{{sign.terminal.createTime}}</span>
      </div>
      <div class="mt-4 f-600 flex" style="font-size: 12px">
        <span>Signature:</span>
        <img v-if="sign.terminal.eSignature" :src="sign.terminal.eSignature" class="ml-2" style="width: 100px;" />
      </div>
    </div>
    <div class="w-full mt-4 sm:w-1/2 sm:mt-0">
      <div class="f-600">Barge Representative</div>
      <div class="mt-4 f-600" style="font-size: 12px">
        <span>Name:</span>
        <span class="ml-2">{{sign.barge.name}}</span>
      </div>
      <div class="mt-4 f-600" style="font-size: 12px">
        <span>Date and Time:</span>
        <span class="ml-2">{{sign.barge.createTime}}</span>
      </div>
      <div class="mt-4 f-600 flex" style="font-size: 12px">
        <span>Signature:</span>
        <img v-if="sign.barge.eSignature" :src="sign.barge.eSignature" class="ml-2" style="width: 100px;" />
      </div>
    </div>
  </div>
</div>
</template>
<script>
import {saveOrUpdateForms, selectFromById} from "@/services/form";
import { jettyHoseList } from "@/utils/constants";

export default {
  name: "RecordingReport",
  props:{
    disabled:{
      type:Boolean
    },
    sign:{
      type:Object
    },
    nominationData: {
      type:Object
    },
  },
  data(){
    return {
      declarationRecordReport:{},
      formType:'',
      params:{},
      hoses: [],
    }
  },
  mounted() {
    if (this.$route.query) {
      this.params = this.$route.query
      this.getDetail()
      this.getHoseList()
    }
  },
  methods:{
    save(){
      const data = {
        declarationRecordReport:{
          ...this.declarationRecordReport,
          nominationId : this.params.id
        },
        nominationId : this.params.id,
        formType:this.formType
      }
      saveOrUpdateForms(data).then(res=>{
        this.getDetail()
        this.$emit('update')
        this.$message.success('Save Success')
      }).catch(()=>{
        this.$emit('update')
      })
    },
    getDetail(){
      selectFromById({
        nId: this.params.id,
        fId: 12
      }).then(res=>{
        this.declarationRecordReport = res.data.declarationRecordReport
        this.formType = res.data.formType
      })
    },
    getHoseList() {
      const jettyHose = jettyHoseList.filter(item => item.jettyNo == this.nominationData.jettyNo)
      this.hoses = jettyHose.length ? jettyHose[0].hoses : []
    },
  },
}
</script>


<style scoped lang="scss">
.form {
  font-size: 15px;
  color: #82889C;
  background-color: #fff;
}
.item{
  display: flex;
  flex-wrap: wrap;
  .label{
    width: 150px;
  }
  .el-textarea{
    flex: 1;
  }
}

ul{
 list-style: decimal;
  padding-left: 15px;
}
</style>
